

























































































































import VueBase from '../../VueBase'
import { Component } from 'vue-property-decorator'
import Trend from '@/views/project/components/trend.vue'

import Distribution from '@/views/project/components/distribution.vue'
@Component({
  name: 'ProjectEdit',
  components: {
    Trend,
    Distribution,
  },
})
export default class ProjectEdit extends VueBase {
  private submitForm: any = {
    type: 'docx',
    pid: '',
    report_name: '',
    sca: '',
    vuln: [],
    version: '',
  }

  private day_num: any = []
  private level_count: any = []

  private async getProject(){
    if (!this.$route.params.pid) return
    const { status, msg, data } = await this.services.project.projectsSummary(
      this.$route.params.pid,
      this.submitForm.version
    )
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }

    this.day_num = data.day_num
    this.level_count = data.level_count

    console.log(data)
  }


  private versionOptions: any = []

  private scaMap: any = {}
  private vulnMap: any = []
  private defaultProps:any =  {
    children: 'type_value',
    label: 'vul_name'
  }
  private showVulnMap = false
  private showScaMap = false

  private async getVulLevels() {
    const {data, status} = await this.services.project.vulLevels()
    if(status !== 201) return
    this.showScaMap = !!data?.length
    this.scaMap = this.showScaMap && data.map((ele:any) => {
      ele.checked = true
      return ele
    }) || []
    this.scaMap?.sort((a: any, b:any) => b.id-a.id)
    console.log('this.scaMap', this.scaMap)
  }
  private async getTypes() {
    const {data, status} = await this.services.project.strategyTypes()
    if(status !== 201) return
    let select: any = []
    this.vulnMap = data.map((item: any) => {
      item.strategy_id = item.level_name
      item.vul_name = item.level_name
      item.isCollapse = item.type_value?.length > 0
      item.type_value = item.type_value.map((ele: any) => {
        ele.isCollapse = false
        select.push(ele.strategy_id)
        return ele
      })
      return item
    }).filter((item: any) => item.isCollapse)
    ;(this.$refs.tree as any)?.setCheckedKeys(select)
    console.log('this.vulnMap', this.vulnMap, select)
  }
  private async getSummary() {
    const route: any = this.$route
    this.submitForm.pid = route.params.pid
    const res = await this.services.project.reportSummary(route.params.pid)
    if (res.status !== 201) return

    var version_list = Object.values(res.data.version_info)
    this.submitForm.report_name = res.data.pro_title
    this.versionOptions = version_list.map((item: any) => {
      return {
        label: item.version_name,
        value: item.id,
      }
    })
    this.submitForm.version = this.versionOptions[0].value
    this.getVulLevels()
    this.getTypes()
  }


  private dataURLtoFile(dataurl: string) {
    var arr: any = dataurl.split(','),
      mime: any = arr[0].match(/:(.*?);/)[1],
      bstr: any = atob(arr[1]),
      n: any = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  private async enter() {
    this.submitForm.sca = this.scaMap.filter((item: any) => item.checked)
      .map((item: any) => item.id).toString()
    let ids = ((this.$refs.tree as any)?.getCheckedKeys() || []).filter((item:any) => typeof item === 'number')

    const trend: any = this.$refs.trend
    const scale: any = this.$refs.scale
    this.$nextTick(async () => {
      const formData: any = new FormData()
      formData.append('type', this.submitForm.type)
      formData.append('pid', this.submitForm.pid)
      formData.append('report_name', this.submitForm.report_name)
      formData.append('level_png', this.dataURLtoFile(scale.pngUrl))
      formData.append('trend_png', this.dataURLtoFile(trend.pngUrl))
      formData.append('app_vul_type', ids.join(','))
      formData.append('sca_level', this.submitForm.sca)
      formData.append('version_str', String(this.submitForm.version))
      const res = await this.services.project.asyncAdd(formData)
      if (res.status === 201) {
        this.$message.success(res.msg)
        this.$router.push('/project/appDetail/' + this.$route.params.pid)
      } else {
        this.$message.error(res.msg)
      }
    })

    if (this.submitForm.report_name === '') {
      this.$message.error('请输入报告名称')
      return
    }
  }

  private rules = {
    report_name: [
      { required: true, message: '请输入报告名称', trigger: 'blur' },
    ],
  }

  async created() {
    await this.getSummary()
    await this.getProject()
  }
}
