










import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import * as echarts from 'echarts'

@Component({
  name: 'Trend',
})
export default class Index extends VueBase {
  @Prop() data!: any
  @Prop() width?: number

  @Watch('data', { immediate: true, deep: true })
  @Prop({ default: true })
  showAnimation?: boolean

  public pngUrl = ''

  onDataChanged() {
    this.randerEchart()
  }

  mounted() {
    this.randerEchart()
  }
  randerEchart() {
    const XData: any = []
    const YHData: any = []
    const YMData: any = []
    const YLData: any = []
    const YNData: any = []
    this.data.forEach((item: any, index: number) => {
      XData.push(item.day_label)
      YHData.push(item.day_num_level_1)
      YMData.push(item.day_num_level_2)
      YLData.push(item.day_num_level_3)
      YNData.push(item.day_num_level_5)
    })

    const option: any = {
      animation: this.showAnimation,
      tooltip: {
        trigger: 'axis',
        padding: 0,
        formatter: (params: any) => {
          let content = ''
          params.forEach((item: any) => {
            content += `
              <div style="display: flex;justify-content: space-between;align-items: center;margin-top:4px;">
                  <p style="color:#4B5563;font-size:12px;line-height:20px;">
                    <span style="display: inline-block;margin-right:8px;width:8px;height:8px;border-radius: 50%;background-color:${item.color}"></span>
                    ${item.seriesName}
                  </p>
                  <span>${item.value}</span>
              </div>
            `
          })
          return `
            <div style="padding: 6px 12px;min-width: 130px;border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000014;">
              <div style="font-size:12px;color:#4B5563;line-height:20px;margin-bottom:8px;">${params[0].axisValueLabel}</div>
              ${content}
            </div>
          `
        },
      },
      legend:false,
      // legend: {
      //   bottom: 10,
      //   itemGap: 24,
      //   itemWidth: 16,
      //   itemStyle: {
      //     opacity: 0,
      //   },
      //   data: ['高危', '中危', '低危', '提示'],
      // },
      grid: {
        left: 0,
        right: 20,
        bottom: 40,
        top: 5,
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: XData,
          axisLabel: {
            show: true,
            margin: 10,
            textStyle: {
              color: '#9CA3AF', //X轴文字颜色
              fontSize: 12,
              lineHeight: 18,
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: '#D1D5DB',
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D1D5DB',
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            show: true,
            margin: 10,
            textStyle: {
              color: '#9CA3AF', //X轴文字颜色
              fontSize: 12,
              lineHeight: 18,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#E5E7EB',
            },
          },
        },
      ],
      series: [
        {
          name: '高危',
          type: 'line',
          showSymbol: false,
          // symbol: 'circle',
          // symbolSize: 5,
          color: ['#EF4444'],
          // itemStyle: {
          //   borderColor: '#ffffff',
          //   borderType: 'solid',
          //   borderWidth: '1',
          // },
          data: YHData,
        },
        {
          name: '中危',
          type: 'line',
          showSymbol: false,
          color: ['#F59E0B'],
          data: YMData,
        },
        {
          name: '低危',
          type: 'line',
          showSymbol: false,
          symbolSize: 5,
          color: ['#0EA5E9'],
          data: YLData,
        },
        {
          name: '提示',
          type: 'line',
          showSymbol: false,
          symbolSize: 5,
          color: ['#64748B'],
          data: YNData,
        },
      ],
    }
    const dom = (document as any).getElementById('trend')
    if (!dom) {
      return
    }
    const myChart = echarts.init((document as any).getElementById('trend'))
    myChart.setOption(option)

    this.pngUrl = myChart.getDataURL()
  }
}
